import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import CallToAction from "../../../components/Article/CallToAction"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import i18n from "i18next"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Facebook Pixel. Czym jest, jak działa i do czego służy?",
        description:
          "Dowiedz się, do czego może okazać się przydatny Facebook Pixel i jaki jest zakres jego możliwości oraz sprawdź, dlaczego jest przydatny dla przedsiębiorców.",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id": "/baza-wiedzy/artykul/co-daje-zainstalowanie-facebook-pixel/",
                name: "Facebook Pixel. Czym jest, jak działa i do czego służy?",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/co-daje-zainstalowanie-facebook-pixel",
            text: "Co daje zainstalowanie Facebook Pixel?",
          },
        ]}
      />
      <Article img={<img src={data.file.childImageSharp.fluid.src} alt={"Co daje zainstalowanie Facebook Pixel?"} />}>
        <h1>Co daje zainstalowanie Facebook Pixel?</h1>
        <p>
          <strong>
            Reklama w sieci stanowi obecnie istotny element niemal każdego biznesu. W związku z tym twórcy rozwiązań w
            tym zakresie wychodzą naprzeciw oczekiwaniom reklamodawców. Aby sprawdzać i wzmocnić skuteczność swoich
            działań marketingowych, należy uważnie analizować statystyki i trafiać do odpowiednich grup docelowych.
            Jednym z ważniejszych narzędzi służących do tego celu jest Facebook Pixel.
          </strong>
        </p>
        <h2>Co to jest Facebook Pixel?</h2>
        <p>
          Facebook Pixel to narzędzie nieco podobne do Google Analytics. Jest fragmentem JavaScript, który służy do
          badania ruchu na stronie i opracowywania skutecznych kampanii reklamowych.{" "}
        </p>
        <p className="inserted-seo-link">
          <a
            href="https://blog.wenet.pl/seo-pozycjonowanie/czym-jest-audyt-seo-dlaczego-jest-potrzebny-wlascicielom-stron-www/"
            target="_blank"
            rel="noreferrer"
          >
            Co to jest audyt SEO
          </a>{" "}
          strony internetowej? Dowiedz się już teraz!
        </p>
        <p>
          Ze strony technicznej Facebook Pixel to indywidualny kod, który kopiuje się do kodu źródłowego strony
          internetowej. Dzięki niemu aplikacja ma możliwość odbierania informacji o działaniach użytkowników na stronie,
          m.in. liczbie odwiedzin, długości przebywania, liczbie konwersji itp.{" "}
        </p>
        <h2>Do czego służy Facebook Pixel?</h2>
        <p>Korzystanie z Facbook Pixel pozwala m.in. na:</p>
        <ul>
          <li>Wgląd w szczegółowe statystyki dotyczące odwiedzin witryny.</li>
          <li>
            Liczenie konwersji, czyli wskazanie liczby wykonanych i pożądanych działań przez użytkowników (np.
            dokonanych zakupów lub wypełnień formularza).
          </li>
          <li>
            Stosowanie remarketingu, czyli metody na docieranie do użytkowników, którzy wcześniej wykazali
            zainteresowanie firmą lub produktem poprzez odwiedziny danego adresu internetowego, lecz jeszcze nie
            dokonali zakupu.{" "}
          </li>
          <li>
            Trafianie do użytkowników zainteresowanych podobnymi treściami jak dotychczasowi odwiedzający i kupujący.
          </li>
        </ul>
        <p className="inserted-seo-link">
          Zleć <a href="/">audyt strony WWW</a> i ulepsz jej widoczność w wyszukiwarce.
        </p>
        <CallToAction
          to={"/"}
          text={"Wykonaj darmowy audyt strony i popraw jej widoczność w wyszukiwarce. Wykonaj teraz >>"}
        />
        <h2>Jak działa Facebook Pixel?</h2>
        <p>
          Korzystanie z Facebook Pixel opiera się na konkretnych krokach. Począwszy od instalacji programu i
          rejestrowania statystyk (ruch na stronie, analiza zachowań), poprzez tworzenie grup docelowych i opracowanie
          strategii działania, aż po monitorowanie zdarzeń, które pozwala opracować najbardziej optymalne strategie
          reklamowe dla firmy.{" "}
        </p>
        <p>
          To bez wątpienia przydatne narzędzie dla każdego przedsiębiorcy, który chce rozwijać swoją działalność w
          sieci. Facebook Pixel to rozwiązanie pozwalające na dokładną analizę zachowań klientów i potencjalnych
          klientów, a także aplikacja dająca możliwość oceny prowadzonej kampanii reklamowej. Bez dostępu do statystyk
          działania e-marketingowe są błądzeniem we mgle.{" "}
        </p>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "img/full/facebook-pixel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
